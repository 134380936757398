.b-feedback {
    display: flex;
    transform: translateY(-4.0625rem);
    @include netbook {
        transform: translateY(0);
        flex-direction: column;
    }

}

.b-feedback .map {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    @include tablets  {
        height: 600px;
    }
}

.b-feedback .form {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 80px 55px;
}

.b-feedback .form .f-form {
    max-width: 535px;
    @include netbook  {
        margin: 0 auto;
    }
}

.f-form .f-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    @include netbook  {
        justify-content: center;
        flex-direction: column;
    }
}

.f-form .f-row+.f-row {
    margin-top: 27px;
}

.f-form .f-row .f-element {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.f-form .f-row .f-element+.f-element {
    margin-left: 17px;
    @include netbook  {
        margin-left: 0;
        margin-top: 1.6875rem;
    }
}

.f-form .f-row .f-element .f-input,
.f-form .f-row .f-element .f-textarea {
    background: none;
    outline: 0 none;
    font-family: "Montserrat-Medium";
    font-family: .8em;
    color: #7c7c7c;
    border: 1px solid #ddd;
    width: 100%;
}

.f-form .f-row .f-element .f-input {
    height: 50px;
    padding: 0 23px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
}

.f-form .f-row .f-element .f-textarea {
    resize: none;
    min-height: 117px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 18px 20px;
}
.f-element label  {
    font-family: $pts-sans;
    font-size: 17px;
    color: #7c7c7c;
}
.certificate  {
    padding: 70px 15px;
    background-size:cover;
    background-position:center;
    transform: translateY(-4.0625rem);
    @include netbook  {
        transform : translateY(0);
    }

    &__wrap  {
        max-width: 980px;
        margin: 0 auto;
        & p {
            width: 95%;
            font-weight: 700;
        }
    }
    &__img  {
        text-align:center;
    }
    &__doc  {
        margin-top: 50px;
        padding: 20px;
    }
    &__license  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & a  {
            flex:50%;
            color:$black;
            font-family: 'Futuris-Bold',Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight:bold;
        }
        @include netbook  {
            flex-direction: column;
            align-items: normal;
        }
    }
    
}
.about__wrap-img  {
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 20px;
}
//colors
$white:                       #ffffff;
$ellow:                       #f4e300;  
$black:                       #000000;

//fonts
$font-size:                   14px;
$ffc :            'FuturaFuturisC';
$fbold :        'Futuris Cyrillic';
$gotham-pro :         'Gotham Pro';
$pts-sans :              'PT Sans';

//media queries
$screen-i5:                   320px;
$screen-xs:                   480px;
$screen-sm:                   768px;
$screen-md:                   992px;
$screen-lg:                   1367px;
$screen-xl:                   1920px;


.carousel  {
    position: relative;
}
.wrap  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 54vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 100px 15px 140px;
    text-align: center;
    @include desktop {
        width: 95vw;
    }
}

.wrap  .label {
   width: 80%;
   margin: 0 auto;
   color: #eee;
   font-family:$gotham-pro;
   font-size: 16px;
   font-weight: 700;
   line-height: 22px;
   margin-bottom: 60px;
   @include desktop  {
         margin-bottom: 30px;  
   }
   

}
.carousel-item  {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
}

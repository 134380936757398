.footer  {
        padding: 70px 0px 0;
        transform: translateY(-4.0625rem);
        background: #2b2a29;
        @include netbook {
            transform: translateY(0);
        }
        @include  phones  {
            padding: 30px 0px 0;
        }
    &-top  {
        color: white;
        padding: 0 15px;
    }
    &-bottom  {
        background: #000000;
    }
    &-wrap  {
        max-width: 1140px;
        margin: 0 auto; 
        display: flex;
        justify-content: space-between;
        &__bottom  {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:15px 0;
            & .copy  {
                font-family: $pts-sans;
                font-size: 14px;
                color: #a1a1a1;
                & p  {
                    @include  netbook  {
                        margin-bottom: 15px;
                    } 
                }
            }
        }
        @include netbook  {
            flex-direction: column
        }
    }
    &__social svg {
        width: 17px;
        height: 17px;
        fill: #a1a1a1;
        margin-right: 20px;
        &:hover   {
            fill:$ellow;
        }
    }
    &__block  {
        // flex: 1;
        margin-right: 48px;
        @include netbook  {
            margin-right: 0;
        }
    
    }
    &__block p {
        margin-top: 15px;
        color: #a1a1a1;
    }
    &__title {
        font-size: 15px;
        text-transform: uppercase;
         font-family: 'Futuris-Bold',Arial, Helvetica, sans-serif;
         font-weight: bold;
        color: $white;
        margin-bottom: 48px;
        @include netbook  {
            margin-bottom: 30px;
        }
        @include tablets  {
            text-align: center;
        }
    }
    &__list  {
        padding: 0  0  0 30px;
        @include tablets  {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            padding: 0;
        }
    }
     &__list li  {
         margin-bottom: 15px;
         color: #a1a1a1;
         position: relative;
         @include tablets  {
             text-align: center;
         }
         &::before  {
            position: absolute;
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $ellow;
            top: 10px;
            left: -30px;
            @include tablets  {
                display: none;
            }
         }
     }
     &__contact  {
          padding: 0 0 0 40px;
          @include tablets  {
              display: flex;
              flex-direction: column;
              align-items: center;
          }

     }

     &__contact li  {
         margin-bottom: 30px;
     }
     &__contact li  a{
         color: $white;
     }
    &__contact .mail {
        text-decoration: underline;
        color: #a2deff;
        position: relative;
        &::before  {
            content: '';
            position: absolute;
            left: -27px;
            top: 2px;
            width: 20px;
            height: 20px;
            background : url(../img/icons/message.svg)no-repeat;
        }
        &:hover  {
            text-decoration: none;
        }
    }
     &__contact .phone  {
         margin: 0;
     }
     &__content  {
         display: flex;
         & .footer__block:last-child  {
             margin-right: 0;
         }
         @include tablets  {
            flex-direction: column;
         }
        &-logo  {
             flex: 40%;
             display: flex;
            @include netbook  {
                flex: 1;
                margin-right: 0;
                margin-bottom: 40px;
                text-align: center;
              
            }
        }
     }
}
@font-face {
	font-family: 'FuturaFuturisC';
	src: local('FuturaFuturisC'),
		url('../fonts/FuturaFuturisC.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Futuris-Bold';
	src:url('../fonts/Futuris-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
		url('../fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PT Sans';
	src: local('PT Sans'), local('PTSans-Regular'),
		url('../fonts/PTSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
html {
  font-size: 16px;
  font-family: $pts-sans;
  @include desktop {
    font-size: 15px;
  }
  @include netbook {
    font-size: 14px;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.42;
  -webkit-font-snoothing: antialiased;
  -moz-osx-font-snoothing: grayscale;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  position: relative;
}


*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}
img  {
  max-width: 100%;
}
.b-header {
    position: fixed;
    top: 0;
    z-index: 999;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    -webkit-transition: background .15s ease-in;
    -o-transition: background .15s ease-in;
    transition: background .15s ease-in;
}
.b-header .holder {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 15px;
    @include tablets  {
            justify-content: space-between;
    }
}

.b-header .holder .navigation  {
        display: flex;
        justify-content: space-between;
        @include desktop  {
                flex-direction: column;
                align-items: flex-end;
        }
        @include  tablets  {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                
        }
}
.b-header .holder .navigation .menu  {
        display: flex;
        align-items: center;
        color: #fff;
        @include tablets  {
                flex-direction: column;
        }
        & li:last-child  {
            margin-right: 0;    
        }
}
.b-header .holder .logo  {
        margin-right: 20px;
        position: relative;
        z-index: 3;
}
.menu .item  {
    margin-right: 30px;
    text-transform: uppercase;
    white-space: nowrap;
    @include tablets  {
            margin-bottom: 10px;
                margin-right: 0px;
    }
}
.navigation .contact {
        display: flex;
        @include desktop {
                margin-top: 10px;
       
        }
        @include tablets  {
                display: flex;
                flex-direction: column;
                align-items: center;
        }
}
.phone {
        margin:0 48px;
        position: relative;
        white-space: nowrap;
}
.phone::before  {
        content: ''; 
        position: absolute;
        left: -27px;
        top: 2px;
        width: 20px;
        height: 20px;
        background:  url(../img/icons/phone.svg)no-repeat;
}
 .adress {
        position: relative;
        white-space: nowrap;
}
.adress::before  {
        content: '';
        position: absolute;
        left: -27px;
        top: 2px;
        width: 20px;
        height: 20px;
        background : url(../img/icons/adress.svg)no-repeat;
}
.navigation a {
        color: $white;
        font-family: $ffc;
        text-decoration: none;
}
.menu a  {
        position: relative;
        
}
.menu a::after  {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: $ellow;
        bottom: -5px;
        left: 0;
        transition: .3s;
        opacity: 0;
}
.menu a:hover::after  {
        opacity: 1;
        width: 20px;

}
.plate  {
        background: #2b2a29;
}
// burger 


.b-header .holder .burger {
        width: 40px;
        height: 30px;
        cursor: pointer;
        display: none;
        margin-left: 20px;
        @include tablets {
                display: block;
                position: relative;
                z-index: 10;
        }
}
.b-header .holder .burger .item {
        height:0.3rem;
        background: #fff;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        position: relative;
}

.b-header .holder .burger .item+.item {
        margin-top: 5px;
}

.b-header .holder .burger.active .item.top {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 8px;
}

.b-header .holder .burger.active .item.middle {
        opacity: 0;
}

.b-header .holder .burger.active .item.bottom {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        bottom: 11px;
}
.b-header .holder .navigation.show {
        position: fixed;
        opacity: 1;
        visibility: visible;
        top: 0;
        left: 0;
        background: #2b2a29;
        width: 100%;
        min-height: 100vh;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        transition: .3s;
}
.partners  {
    padding: 70px 0;
    background-size:cover;
    background-position:center;
    transform: translateY(-4.0625rem);
    @include netbook {
        transform: translateY(0);
    }
    &__wrap  {
        max-width:1436px;
        margin:0 auto;
    }
    .flickity-enabled  {
        padding: 0 90px;
        @include tablets  {
            padding: 0;
        }
    }
    &__slide  {
        width: 235px;
        padding: 0 13px;
    }
    &__slide-img  {
        width: 100%;
        height: 232px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__slide-pic  {
        width: 190px;
        overflow:hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__descript  {
        text-align: center; 
        color: $white;
        margin-top: 30px;
    }
    &__title  {
        font-family: 'Futuris-Bold',Arial, Helvetica, sans-serif;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 25px;
    }
    &__text  {
        font-family: $pts-sans;
        font-size: 13px;
    }
}
.flickity-prev-next-button.next,
.flickity-prev-next-button.previous {
    top: 105px !important;
    @include tablets  {
        display: none !important;
    }
}

.about  {
    display: flex;
    font-family: $pts-sans;
    transform:translateY(-65px);
    @include netbook  {
        transform:translateY(0px);
        flex-direction: column;
    }
    &__description  {
        display: flex;
        justify-content: center;
        flex:1;
        padding: 90px 60px 0 10px;
        background: $ellow url(../img/line.png)no-repeat;
        background-size: center;
        background-size: cover;
        border-top-right-radius: 5%;
        @include  netbook  {
             border-top-right-radius: 0;
        }
        @include tablets  {
            flex-direction: column;
            padding: 20px;
        }
    }
    &__img  {
        flex: 40%;
        @include tablets  {
            align-self: center;
        }
        @include phones  {
            display: none;
        }
    }
    &__text  {
        flex:60%;
        @include tablets  {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    &__list  {
        padding: 90px 0px 0px 60px;
        background:#fff url(../img/line.png)no-repeat;
        background-size: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        flex:1; 
        flex-wrap: wrap;
        border-top-left-radius: 5%;
        @include netbook {
            border-top-right-radius: 0;
        }
        @include phones  {
            padding: 45px 0px 0px 60px;
        }
        
    }
    &__item  {
        flex: 35%;
        margin:0  50px 40px 0 ;
    }
    &__wrap {
        display: flex;
        margin-bottom: 35px;
        align-items: center;
    }
    &__title  {
        text-decoration: underline;
        font-family: 'Futuris-Bold', Arial, Helvetica, sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-left: 25px;
            
    }
    &__icon  {
        width: 50px;
        height: 50px;
    }
    &__modal  {
        max-width: 980px !important;
        border-radius: 10px;
        & p  {
            margin-bottom: 20px;
        }
        & img {
            text-align: center;
        }
    }

}
.modal__wrap  {
    width: 95%;
    margin: 0 auto;
}
.title  {
    text-transform: uppercase;
    color: #eee;
    font-size: 42px;
    font-weight: 700;
    position: relative;
    font-family: 'Futuris-Bold',Arial, Helvetica, sans-serif;
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 50px;
    text-align: center;
    &-partners  {
        padding-bottom: 34px;
        margin-bottom: 52px;
    }
    &::after  {
        content: '';
        position: absolute;
        width: 60px;
        height: 3px;
        background-color: #f4e300;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%)
    }
    &__blacked  {
        color: $black;
        &::after  {
            background-color: $black;
        }
    }
    &__black {
        color: $black;
        position: relative;
        &::after {
            left: 0;
        }
    }
    &__blackoff {
        color: $black;
        position: relative;
        text-align: left;
        padding-bottom:0;
        margin-bottom: 52px;
        &::after {
            display: none;
        }
        @include netbook  {
            text-align: center;
        }
    }
    &__blacks  {
        color: black;
        display: inline-block;
        position: relative;
        &::after  {
            left:30px;
            background-color: black;
        }
    }
    &__linenone  {
        padding: 0;
        margin-bottom:40px;
        font-size: 28px;
        &::after  {
            display: none;
        }
    }

    @include desktop {
        font-size: 28px;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    @include phones {
        font-size: 28px;
    }
}

.g-button  {
    width: 230px;
    height: 66px;
    white-space: nowrap;
    background-color: #f4e300;
    border-radius: 33px;
    text-transform: uppercase;
    color: black;
    font-family: 'Futuris-Bold',Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 25px 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    outline: none;
    transition: .3s;
    &:hover  {
        color: #0056b3;
        text-decoration: underline;
    }
    @include desktop  {
            padding: 12px 20px;
    }
    &__send  {
        width: 195px;
        height: 60px;
        color: $white;
        background: #26B2ED;
        line-height: 6px;
        @include phones {
            margin: 0 auto;
        }
    }
    &__white  {
        background: #fff;
        margin-top: 50px;
        margin-bottom: 20px;
    }
  
}

.form .right {
    @include tablets  {
        text-align: center;
    }
}

input.error  {
    border: red 1px solid !important;
}
textarea.error  {
    border: red 1px solid !important;
}

label.error{
    color: red !important;
    font-family: $pts-sans !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
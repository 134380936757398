#modal  {
    border-radius: 10px;
    padding: 24px 50px;
}
.form {
    font-family: $pts-sans;
    font-size: 16px;
    & span {
        color: red;
    }
    & .textarea  {
        display: flex;
        flex-direction: column; 
      
    }
    & #textarea  {
          height: 123px;
          background-color: white;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
    }
    & .conf  {
        color: #6bb6f8;
        text-decoration: underline;
        font-size: 15px;
    }
      & .conf:hover  {
          text-decoration: none;
      }
    & p  {
        color: #a8a8a8;
    }
    & .right  {
        text-align: right;
    }
}
input[type="submit"] {
    @include tablets {
        margin: 0 auto
    }
}

.captcha__center  {
    display: flex;
    align-items: center;
}

.form .captcha  {
    display: flex;
    justify-content: space-between;
    @include tablets  {
        flex-direction: column;
        align-items: center;
    }
}

.form .captcha img  {
    width: 102px;
    height: 49px;
    overflow: hidden;
    @include  tablets  {
        width: 100%;
    }
}
.form .captcha input  {
    @include  tablets  {
        width: 100%;
    }
}


